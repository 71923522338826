@import 'assets/fontawesome/scss/fontawesome';
@import 'assets/fontawesome/scss/brands';

$fa-font-path: 'assets/fontawesome/webfonts';

@import 'assets/scss/variables';
@import 'assets/scss/mixins';
@import 'assets/scss/bootstrap';
@import 'assets/scss/theme';



// Custom Styles
.content-container {
  img {
    max-width: 100%;
    height: auto;
  }

  .btn-primary {
    background-color: #f15b56 !important;
    border-color: #f15b56 !important;
    font-size: 20px;
    padding: 0.1em 0.5em;
    text-transform: uppercase;
    color: #fff;
    border-radius: 0px;
    text-decoration: none;
  }

  figure.image_resized {
    display: block;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  ul,
  ol {
    list-style: disc;
    padding-left: 2em;
    margin-bottom: 1em;
  }

  ol {
    list-style: decimal;
  }
}



.spinner-border {
  width: 6em;
  height: 6em;
  border-width: 0.6em;
  color: #051421;
}
